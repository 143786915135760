.themes-v1 {
	.theme-unit {
		padding: 12px 16px;
		background-color: #D9D9D9;
		margin-bottom: 30px;

		@media (min-width:768px) {
			height: 440px;
		}

		img {
			width: 100%;
		}

		.theme-content {
			text-align: left;
		}

	}


	a {
		color: var(--font-color-main);

		&:hover {
			color: var(--link-font-color);
		}

	}


	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}



	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;

		@media (max-width: 992px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
	}

	.theme-card-3--description-container {
		background-color: rgb(56 62 66 / 50%);
		padding: 10px;
		-webkit-transition: 1s;
		transition: 1s;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: Center;
		justify-content: center;

		&:hover {
			background-color: rgb(56 62 66 / 85%) !important;
		}
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}



		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			background-color: rgba(88, 38, 28, .85);
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}