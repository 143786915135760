.unit-booking-v1 {
	background-color: var(--color-grey-light);
	padding: 20px;

	@media(max-width:767px) {
		padding: 10px;
	}


	.travel-data {

		padding: 10px;
		background-color: var(--color-grey-normal);
		scroll-margin: var(--scroll-margin);
		border-radius: var(--border-radius);

		.travel-field {
			align-self: baseline;
			margin-bottom: 5px;

			@media(max-width: 991px) {
				padding-bottom: 5px;
			}
		}

		.travel-period-input {
			background-color: transparent;
			border: none;
			min-width: 250px;
		}

		.label {
			min-width: 120px;
			display: inline-block;
		}

		.warn {
			font-weight: 700;
		}

		.reset-btn {
			@media(max-width: 991px) and (min-width: 600px) {
				float: right;
			}

			@media(max-width:600px) {
				margin-left: 125px;
			}

			@media(max-width:460px) {
				margin-left: inherit;
			}

		}

		.form-control {
			text-align: left;

			&.custom {
				display: inline-block;
				width: 200px;
				font-size: var(--font-size-md);
				cursor: pointer;

				&.warn {
					border: 1px solid var(--color-red);
				}
			}
		}

		.guest-box {

			position: relative;
			max-width: 200px;
			display: inline-block;

			.fa {
				position: absolute;
				right: 10px;
				top: 10px;
				pointer-events: none;
			}
		}
	}

	.booking-details {

		.table {
			@media(max-width:767px) {
				font-size: 0.875rem;
			}

			td {
				border-top: 2px solid var(--color-white);
			}

			tbody {
				border-top: 2px solid var(--color-white);
			}

			.btn-sm {
				padding: 4px 8px;
				margin-right: 5px;
			}
		}

		.optionals {
			margin-top: 20px;
		}

		.optionals-title {
			font-size: var(--h4-font-size);
			font-weight: var(--h4-font-weight);
			padding-left: 10px;
		}

		.consumptioncosts-title {
			font-size: var(--h5-font-size);
			font-weight: var(--h5-font-weight);
			padding-left: 10px;
		}

		.button-row {
			text-align: right;
			margin-top: 20px;

			.bookbutton {
				@media(max-width:415px) {
					font-size: 14px;
				}
			}

		}

		.subrow {
			margin-top: 20px;
		}

	}

	.bookbutton,
	.btn-option {
		@media(max-width: 500px) {
			width: 100%
		}
	}
}