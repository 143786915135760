.timmendorfer-v2 {
	.FindetEure-v1 {
		.row {
			justify-content: center;
		}
	}

	.FindetEure-col {
		.h3 {
			margin: 15px 0 20px;
		}

		@media (max-width:992px) {
			border: 1px solid var(--color-secondary) !important;
		}
	}
}