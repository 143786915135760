.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

}

.blog-list-v1 {
	.img-content {
		img {
			height: 350px !important;
			max-height: 350px;
			object-fit: cover;
			margin-bottom: 15px;
			border-radius: 0;
			border: 0;
			padding: 0;
		}
	}

	.blogcontent {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 10%);
		background-color: #fff;
		background-color: var(--color-white);
		padding: 10px;
		height: 100%;
		padding-bottom: 60px;
		overflow: hidden;
		position: relative;

		.button {
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			margin: 0;
		}
	}

	.blog-bl {
		margin-bottom: 30px;

		.ribbon {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			overflow: hidden;
			width: 110px;
			height: 110px;
			text-align: right;
		}

		.ribbon span {
			font-size: 16px;
			font-weight: 500;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			line-height: 25px;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			width: 160px;
			display: block;
			background: var(--color-secondary);
			/*	box-shadow: 0 3px 10px -5px #000; */
			position: absolute;
			top: 31px;
			left: -39px;

		}
	}
}