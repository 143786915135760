.urlaub-Ostsee-v1 {
	h2 {
		margin-bottom: 30px;
	}

	.urlaub-wraper {
		margin: 0 -15px;

		@media (max-width:1199px) {
			margin: 0;
		}

		.urlaub-Ostsee {
			background: var(--color-white);
			border-radius: 8px;
			padding: 20px 30px;
			height: 100%;
			margin: 0 15px;

			@media (max-width:1199px) {
				margin: 0;
			}

			h3 {
				font-size: var(--font-size-main);
			}

			.fa {
				background: var(--color-primary);
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				color: var(--color-white);
				margin: 0 auto 20px;
			}
		}
	}
}

.niendorf-Ostsee-v1 {
	h2 {
		margin-bottom: 15px;
		margin-top: 30px;
	}
}

.successfully {
	background-color: var(--color-primary);
	color: var(--color-white);

	h2 {
		color: var(--color-white);
	}
}

.eigentumer-faq {
	.card-header {
		background-color: var(--color-white);
		border-bottom: none;
		padding: 7px 13px;
		margin-bottom: 13px;
	}
}

.static-view {
	.apartment-bl {
		.col5 {
			-webkit-box-flex: 0;
			flex: 0 0 20%;
			max-width: 20%;
			padding: 0 15px;

			@media(max-width:1200px) {
				-webkit-box-flex: 0;
				flex: 0 0 50%;
				max-width: 50%;
			}



			@media(max-width:550px) {
				flex: 0 0 100%;
				max-width: 100%;
			}

			.number-bl {
				position: relative;
				width: 100%;
				background-color: rgba(246, 247, 247, 1);
				border-radius: 15px;
				padding: 10px 15px;
				margin: 0 auto 30px;

				@media (min-width: 1500px) {
					max-width: 218px;
					padding: 10px 20px;
				}

				@media (min-width:768px) {
					min-height: 280px;
				}

				p {
					color: rgba(56, 62, 66, 1);

				}
			}



			h3,
			.h3 {
				text-align: center;
				margin-bottom: 26px;
				font-size: 18px;
				min-height: 45px;
				color: rgba(56, 62, 66, 1);
			}

			span {
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: var(--color-primary);
				color: var(--color-white);
				font-size: 30px;
				border-radius: 50%;
				margin: 0 auto 30px;
			}
		}
	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);
		padding: 0;

		&:after {
			float: right !important;
			font-family: Font Awesome\ 5 Free;
			content: "\f067";
			padding-right: 5px;
		}

		&[aria-expanded="true"] {
			&:after {
				content: "\f068";
			}
		}

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	.testimonial-slide {
		position: relative;

		.item {
			background-color: rgba(246, 247, 247, 1);
			padding: 30px;

			@media (min-width:1201px) {
				height: 380px;
			}
		}

		.owl-prev {
			position: absolute;
			top: 30%;
			left: -40px;

			@media (max-width:767px) {
				left: 0;
			}

			span {
				font-size: 80px;
			}
		}

		.owl-next {
			position: absolute;
			top: 30%;
			right: -40px;

			@media (max-width:767px) {
				right: 0;
			}

			span {
				font-size: 80px;
			}
		}
	}

	.icon {
		background-repeat: no-repeat;
		background-position: center;

		&.icon-quote {
			background-image: url(RESOURCE/img/icon-quote.svg);
			width: 37px;
			height: 37px;
			display: inline-block;
			margin-bottom: 20px;
		}
	}

	.cl-bl {
		display: flex;

		.icon-img-bl {
			max-width: 55px;
			margin-right: 15px;
		}

		span {
			font-weight: bold;

		}
	}
}



.FindetEure-v1 {
	h2 {
		margin-bottom: 30px;
	}

	.FindetEure-wraper {
		margin: 0 -15px;

		@media (max-width:1199px) {
			margin: 0;
		}



		.FindetEure-col {
			margin: 0 15px 40px;
			border: 1px solid transparent;
			padding: 10px;
			transition: all .2s;

			&:hover {
				transform: scale(1.015);
				border: 1px solid var(--color-secondary);
			}

			a {
				text-decoration: none;
			}

			@media (max-width:1199px) {
				margin: 0 0 40px;
			}

			h3 {
				margin: 15px 0 20px;
			}



			img {
				width: 100%;
			}
		}
	}
}

.Wokann-v1 {
	h2 {
		margin-bottom: 30px;
	}

	.Wokann-wraper {
		margin: 0 -15px;

		@media (max-width:1199px) {
			margin: 0;
		}



		.Wokann-col {
			margin-bottom: 30px;



			&:hover {
				h3 {
					background: var(--color-primary);
					text-decoration: none;
				}
			}

			h3 {
				background: var(--color-secondary);
				padding: 10px 0;
				text-align: center;
				color: var(--color-white);
			}

			img {
				width: 100%;
			}
		}
	}
}

.faq-search-v1 {
	width: 100%;

	h2 {
		margin-bottom: 30px;
	}

	.card {
		border: 0;

		.card-header {
			padding: 10px 15px;
			margin-bottom: 0;
			background-color: #fff;
			border-bottom: 0;
			border-radius: 0 !important;
			border: 0;

			.btn {

				width: 100%;
				text-align: left;
				padding: 0;
				border: 0;
				margin: 0;
				color: #000;
				padding-right: 35px;

				&:hover {
					text-decoration: none;
				}

				&:before {
					content: "";
					height: 4px;
					width: 25px;
					background: #383e42;
					position: absolute;
					right: 20px;
					top: 22px;

					@media (max-width:767px) {
						height: 2px;
						width: 20px;
						top: 20px;
					}
				}

				&:after {
					content: "";
					height: 26px;
					width: 4px;
					background: #383e42;
					position: absolute;
					right: 30px;
					top: 11px;

					@media (max-width:767px) {
						height: 20px;
						width: 2px;
						right: 29px;

					}
				}
			}



			.btn[aria-expanded="true"] {

				&:after {
					display: none
				}
			}
		}
	}
}